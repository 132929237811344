import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import MembershipIcon from 'ui-kit/icons/payment-history/birdi-membership';
import CreditCardOutlineIcon from 'ui-kit/icons/payment-history/credit-card-outline';
import ErrorCircleIcon from 'ui-kit/icons/payment-history/error-circle';
import MoneyOutlineIcon from 'ui-kit/icons/payment-history/money-outline';
import PillOutlineIcon from 'ui-kit/icons/payment-history/pill-outline';
import PlusCircleIcon from 'ui-kit/icons/payment-history/plus-circle';
import StethoscopeIcon from 'ui-kit/icons/payment-history/stethoscope';

import './icon-composer.styles.scss';

type IconType = 'money' | 'membership' | 'pill' | 'visits';
type SubIconType = 'default' | 'error' | 'refund' | 'positive';

const mapIconSrc = (icon: IconType) => {
    switch (icon) {
        case 'money':
            return <MoneyOutlineIcon />;
        case 'membership':
            return <MembershipIcon />;
        case 'pill':
            return <PillOutlineIcon />;
        case 'visits':
            return <StethoscopeIcon />;
    }
};

const mapSubIconSrc = (icon: SubIconType, cardType?: string) => {
    switch (icon) {
        case 'error':
            return <ErrorCircleIcon />;
        case 'refund':
            return <PlusCircleIcon />;
        case 'positive':
            return <PlusCircleIcon />;
        default:
            return cardType ? <CreditCardIcon variant={cardType} isSmallIcon /> : <CreditCardOutlineIcon />;
    }
};

interface IconComposerProps {
    iconType: IconType;
    subIconType: SubIconType;
    orderPaymentCardTypeDesc?: string;
}

const IconComposer = ({ iconType, subIconType, orderPaymentCardTypeDesc }: IconComposerProps) => (
    <div className="icon-composer-icon">
        <div className="icon-composer-icon-type">
            {mapIconSrc(iconType)}
            <div className="icon-composer-icon-type-append">{mapSubIconSrc(subIconType, orderPaymentCardTypeDesc)}</div>
        </div>
    </div>
);

export default IconComposer;
