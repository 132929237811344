/* eslint-disable no-unused-vars */
import { PaymentHistoryLine } from 'types/payment-history';

export enum TransactionTypes {
    REFUND = 'Refund',
    BALANCE_SETTLEMENT = 'Balance settlement',
    CREDIT = 'Credit',
    MEMBERSHIP_FEE = 'Membership fee',
    PRESCRIPTION = 'Prescription',
    ONLINE_VISIT = 'Online visit'
}

export interface TransactionCardTypes extends PaymentHistoryLine {
    transactionType: TransactionTypes;
}

export interface TransactionCardProps {
    transactionInfo: TransactionCardTypes;
    iconType: 'money' | 'membership' | 'pill' | 'visits';
    subIconType: 'default' | 'error' | 'refund';
    hasDivider?: boolean;
    user?: string; // Provisional solution, as it depends on the API and the new fields to deal with caregiver/dependents
    userType?: 'caregiver' | 'dependent';
}
