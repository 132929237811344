import classNames from 'classnames';
import React, { CSSProperties } from 'react';

export interface MembershipIconProps {
    className?: string;
    style?: CSSProperties;
}
const MembershipIcon = ({ className, style }: MembershipIconProps) => {
    const classes = classNames('membership-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.15 8.83589L9.63952 6.94745C8.75161 5.88026 7.15926 5.75911 6.08297 6.66272L6.06031 6.68197C5.01066 7.58439 4.86862 9.16793 5.74304 10.2189L6.58468 11.3284M6.58333 11.3284L2.51642 14.6425C1.89102 15.1684 1.80355 16.1268 2.42019 16.8498C3.03683 17.5728 4.01687 17.47 4.56845 17.0147L5.90943 15.9078C7.27554 17.6541 9.14276 18.5002 11.5941 18.5002C15.2797 18.5002 18.3418 15.9457 18.966 12.5814C19.1212 11.7445 19.4958 10.9598 20.0795 10.3206L21.8119 9.07498C22.0627 8.88636 22.0627 8.52195 21.8119 8.33333C21.0701 7.77521 20.3835 7.14658 19.8168 6.42383C19.1388 5.5594 18.0607 5.00024 16.8463 5.00024C16.8011 5.00024 16.7522 5.00246 16.7007 5.00608C16.1481 5.04683 15.6212 5.23612 15.1677 5.5374C15.1566 5.54432 15.1459 5.5517 15.1352 5.5591L15.133 5.56058C15.0572 5.61233 14.9833 5.66682 14.912 5.72477L13.628 6.768L11.4363 8.56266"
                    stroke="#93A9B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default MembershipIcon;
