import { useTranslation } from 'react-i18next';

import Button from 'ui-kit/button/button';

import './balance-by-member-list.styles.scss';

export type MemberBalance = {
    fullName: string;
    totalDue: string; // type may change on API integration
    isCaregiver: boolean; // remove after API integration
};

export interface BalanceByMemberListProps {
    members: MemberBalance[];
    onClick: () => void;
}

const BalanceByMemberList = ({ members, onClick }: BalanceByMemberListProps) => {
    const { t } = useTranslation();

    return (
        <ul className="balance-by-member-list">
            {members.map((member) => (
                <li className="balance-by-member-list__item" key={member.fullName}>
                    <div className="balance-by-member-list__item__user-info">
                        <p
                            className={
                                member.isCaregiver
                                    ? 'balance-by-member-list__item__user-info__is-caregiver'
                                    : 'balance-by-member-list__item__user-info__not-caregiver'
                            }
                        >
                            {member.fullName.replace(/\B\w+/g, '').replace(/\s/g, '')}
                        </p>
                        <p>{member.fullName}</p>
                    </div>
                    <p className="balance-by-member-list__item__total-due">${member.totalDue}</p>
                    <Button
                        className="balance-by-member-list__item__pay-btn btn-bold btn-outline"
                        label={t('pages.profile.balanceByMember.btnAction')}
                        type="button"
                        variant="outline-primary"
                        onClick={onClick}
                    />
                </li>
            ))}
        </ul>
    );
};

export default BalanceByMemberList;
