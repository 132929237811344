import classNames from 'classnames';
import React, { CSSProperties } from 'react';

export interface StethoscopeIconProps {
    className?: string;
    style?: CSSProperties;
}
const StethoscopeIcon = ({ className, style }: StethoscopeIconProps) => {
    const classes = classNames('stethoscope-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.76248 3H2V8.81119C2 11.6857 4.32217 14.016 7.18672 14.016C10.0512 14.016 12.3734 11.6857 12.3734 8.81119V3H10.611M7.28743 14.0665V16.0195C7.28743 19.3225 9.95571 22 13.2472 22C16.5387 22 19.2069 19.3225 19.2069 16.0195V10.9841M16.4694 7.22623C16.3036 7.95278 16.4322 8.71568 16.8269 9.34708C17.2217 9.97848 17.8502 10.4267 18.5742 10.593C19.2983 10.7594 20.0585 10.6304 20.6877 10.2342C21.3169 9.83814 21.7635 9.20744 21.9293 8.48088C22.0951 7.75432 21.9665 6.99143 21.5718 6.36003C21.1771 5.72863 20.5485 5.28044 19.8245 5.11407C19.1005 4.94769 18.3402 5.07675 17.711 5.47286C17.0818 5.86896 16.6352 6.49967 16.4694 7.22623Z"
                    stroke="#93A9B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default StethoscopeIcon;
