import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import TextSetValue from 'ui-kit/text/textSetValue';
import ToastBox from 'ui-kit/toast-box/toast-box';

import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import PaymentInformation from 'components/checkout/payment-information';
import ManageMembershipPaymentMethodBody from 'components/manage-membership/manage-membership-payment-method-body';
import { ModalComponentContent, ModalComponentFooter } from 'components/modal/modal.component';

import { accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
import {
    accountCreditCardsSelector,
    accountHasInsuranceSelector,
    accountIsMembershipSelector
} from 'state/account/account.selectors';
import { openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { paymentsSelectedMethodSelector } from 'state/payments/payments.selectors';

import './payment-history-modal.styles.scss';

export interface MakePaymentProps {
    handleCloseModal: () => void;
    paymentInformation: {
        isCaregiver: boolean;
        amountDue: number;
    };
}

const MakePayment = ({ handleCloseModal, paymentInformation }: MakePaymentProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { isCaregiver, amountDue } = paymentInformation;

    const [selectedPaymentType, setSelectedPaymentType] = useState<'full' | 'other'>('full');
    const [amountValue, setAmountValue] = useState<string | undefined>(undefined);
    const [hasError, setHasError] = useState<boolean>(false);
    const [remainingAmount, setRemainingAmount] = useState<number | undefined>(undefined);
    const [inputTouched, setInputTouched] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const allPaymentData = useSelector(accountCreditCardsSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const isMembership = useSelector(accountIsMembershipSelector);
    const selectedPayment = useSelector(paymentsSelectedMethodSelector);

    useEffect(() => {
        if (allPaymentData === undefined) {
            dispatch(accountGetAllCreditCardsRoutine.trigger());
        }
    }, [allPaymentData, dispatch]);

    const defaultCreditCard = useMemo(() => {
        return Array.isArray(allPaymentData) && allPaymentData.length > 0
            ? allPaymentData.find((card) => card.defaultCard)
            : undefined;
    }, [allPaymentData]);

    useEffect(() => {
        if (selectedPaymentType === 'full') {
            setAmountValue(amountDue.toFixed(2));
            setHasError(false);
            setRemainingAmount(undefined);
            setInputTouched(false);
        } else {
            setAmountValue(undefined);
        }
    }, [selectedPaymentType, amountDue]);

    useEffect(() => {
        if (selectedPaymentType === 'other') {
            const numericalValue = parseFloat(amountValue || '0');
            if (numericalValue > amountDue || numericalValue <= 0 || isNaN(numericalValue)) {
                setHasError(true);
                setRemainingAmount(undefined);
            } else {
                setHasError(false);
                setRemainingAmount(amountDue - numericalValue);
            }
        }
    }, [selectedPaymentType, amountValue, amountDue]);

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target;

        value = value.replace(/[^0-9.]/g, '');
        setAmountValue(value);
        setInputTouched(true);
    };

    const handleContinue = () => {
        setIsBusy(true);
        if (selectedPaymentType === 'full') {
            console.log(`User will pay full amount: $${amountDue.toFixed(2)}`);
            setTimeout(() => {
                setIsBusy(false);
                handleCloseModal();
            }, 1500);
        } else if (selectedPaymentType === 'other') {
            const numericalValue = parseFloat(amountValue || '0');
            if (numericalValue <= 0 || isNaN(numericalValue) || numericalValue > amountDue) {
                setHasError(true);
                console.log('error');
                return;
            }
            console.log(`User will pay partial amount: $${numericalValue.toFixed(2)}`);
            setTimeout(() => {
                setIsBusy(false);
                handleCloseModal();
            }, 1500);

            if (remainingAmount !== undefined && remainingAmount > 0) {
                console.log(`Remaining amount to pay: $${remainingAmount.toFixed(2)}`);
            }
        }
    };

    const renderToastBox = (message: string, remainingAmount?: number) => {
        const translationOptions = remainingAmount ? { remaningAmount: remainingAmount.toFixed(2) } : undefined;

        return (
            <ToastBox variant="warning" icon="warning">
                <span
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(t(message, translationOptions), {
                            allowedTags: ['strong']
                        })
                    }}
                />
            </ToastBox>
        );
    };

    const displayToast = () => {
        if (!inputTouched) {
            return null;
        }

        const isOverPaying = Number(amountValue) > amountDue;
        const hasRemainingAmount = remainingAmount !== undefined && remainingAmount > 0;

        if (isOverPaying) {
            return renderToastBox(
                'pages.profile.payment.modal.makePayment.amountToPay.options.otherAmount.toast.overPaying'
            );
        } else if (hasRemainingAmount && isMembership) {
            return renderToastBox(
                'pages.profile.payment.modal.makePayment.amountToPay.options.otherAmount.toast.birdiCashPatients',
                remainingAmount
            );
        } else if (hasRemainingAmount && accountHasInsurance) {
            return renderToastBox(
                'pages.profile.payment.modal.makePayment.amountToPay.options.otherAmount.toast.insured',
                remainingAmount
            );
        } else {
            return null;
        }
    };

    const onChangePaymentMethod = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'modal-membership-payment',
                size: 'lg',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t('pages.profile.membership.manageMembership.modals.changePaymentTitle')}
                        subTitle={t('pages.profile.membership.manageMembership.modals.changePaymentSubtitle')}
                        body={
                            <ManageMembershipPaymentMethodBody
                                isModal
                                selectedPayment={selectedPayment || defaultCreditCard}
                                currentFlow="payment-history"
                            />
                        }
                    />
                )
            })
        );
    };

    return (
        <ModalComponentContent>
            <div className="make-payment-modal">
                <h3 className="title">
                    {isCaregiver
                        ? t('pages.profile.payment.modal.makePayment.caregiverDescription')
                        : t('pages.profile.payment.modal.makePayment.description')}
                </h3>
                <div className="amount-to-pay">
                    <div className="amount-options">
                        <span className="amount-options-title">
                            {t('pages.profile.payment.modal.makePayment.amountToPay.title')}
                        </span>
                        {!isCaregiver ? (
                            <div className="family-balance">
                                <div className="family-balance-header">
                                    <span className="family-balance-label">
                                        {t(
                                            'pages.profile.payment.modal.makePayment.amountToPay.options.familyBalance.title'
                                        )}
                                    </span>
                                    <span className="family-balance-amount">${amountDue.toFixed(2)}</span>
                                </div>
                                <ToastBox variant="info" icon="info">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(
                                                t(
                                                    'pages.profile.payment.modal.makePayment.amountToPay.options.familyBalance.disclaimer'
                                                )
                                            )
                                        }}
                                    />
                                </ToastBox>
                            </div>
                        ) : (
                            <>
                                <div className="total">
                                    <label className="total-label">
                                        <input
                                            type="radio"
                                            name="total-amount"
                                            value="full"
                                            checked={selectedPaymentType === 'full'}
                                            onChange={() => setSelectedPaymentType('full')}
                                        />
                                        {t('pages.profile.payment.modal.makePayment.amountToPay.options.fullBalance')}
                                    </label>
                                    <span className="total-amount">${amountDue.toFixed(2)}</span>
                                </div>
                                <div className="other">
                                    <label className="other-label">
                                        <input
                                            type="radio"
                                            name="other-amount"
                                            value="other"
                                            checked={selectedPaymentType === 'other'}
                                            onChange={() => setSelectedPaymentType('other')}
                                        />
                                        {t(
                                            'pages.profile.payment.modal.makePayment.amountToPay.options.otherAmount.label'
                                        )}
                                    </label>
                                    {selectedPaymentType === 'other' && (
                                        <TextSetValue
                                            name="other-amount-input"
                                            label={t(
                                                'pages.profile.payment.modal.makePayment.amountToPay.options.otherAmount.inputPlaceolder'
                                            )}
                                            onChange={onChangeHandler}
                                            type="currency"
                                            autocomplete="off"
                                            value={amountValue || ''}
                                            showErrorsText={false}
                                            touched={inputTouched}
                                            errors={
                                                hasError
                                                    ? t(
                                                          'pages.profile.payment.modal.makePayment.amountToPay.options.otherAmount.inputEmpty'
                                                      )
                                                    : ''
                                            }
                                        />
                                    )}
                                    {displayToast()}
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <PaymentInformation
                    shouldHidePaymentType
                    skeletonHeight={200}
                    isLoading={!defaultCreditCard}
                    onChange={onChangePaymentMethod}
                    selectedPaymentMethod={selectedPayment || defaultCreditCard}
                    title={t('pages.profile.payment.modal.makePayment.paymentInfo.title')}
                />
            </div>
            <ModalComponentFooter
                cancelButtonLabel={t('pages.profile.payment.modal.makePayment.buttons.cancel')}
                onCancel={handleCloseModal}
                continueButtonLabel={t('pages.profile.payment.modal.makePayment.buttons.makePayment')}
                onContinue={handleContinue}
                isCTABusy={isBusy}
                isCTADisabled={hasError || (selectedPaymentType === 'other' && amountValue === '') || isBusy}
            />
        </ModalComponentContent>
    );
};

export default MakePayment;
