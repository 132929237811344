import TransactionCardComponent from 'components/transaction-card';
import { TransactionCardProps } from 'components/transaction-card/transaction-card.types';

import { groupTransactionsByMonth } from 'util/payment-history';

import './transaction-list.styles.scss';

export interface TransactionListProps {
    transactions: TransactionCardProps[];
}

const TransactionList = ({ transactions }: TransactionListProps) => {
    const groupedTransactions = groupTransactionsByMonth(transactions);

    return (
        <div className="transaction-list-container">
            {Object.keys(groupedTransactions).map((monthYear) => {
                const hasManyTransactions = groupedTransactions[monthYear].length > 1;
                return (
                    <div className="transaction-list-container-group" key={monthYear}>
                        <span className="transaction-list-container-group-title">{monthYear}</span>
                        <div
                            className={`transaction-list-container-group-items ${
                                !hasManyTransactions ? 'solo-transaction' : ''
                            }`}
                        >
                            {groupedTransactions[monthYear].map((card) => (
                                <TransactionCardComponent
                                    key={card.transactionInfo.orderPaymentCardSeqNum}
                                    {...card}
                                    hasDivider={hasManyTransactions}
                                />
                            ))}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default TransactionList;
