import classNames from 'classnames';
import React, { CSSProperties } from 'react';

export interface CreditCardIconProps {
    className?: string;
    style?: CSSProperties;
}
const CreditCardOutlineIcon = ({ className, style }: CreditCardIconProps) => {
    const classes = classNames('credit-card-outline-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_38945_15772)">
                    <g clipPath="url(#clip1_38945_15772)">
                        <rect x="-0.00012207" y="4" width="24" height="16" rx="1.33333" fill="white" />
                        <rect x="17.3334" y="10.6667" width="4" height="2.66667" rx="0.666667" fill="#D1E0E5" />
                        <rect y="16" width="24" height="0.666667" rx="0.333333" fill="#D1E0E5" />
                    </g>
                    <rect
                        x="0.333211"
                        y="4.33333"
                        width="23.3333"
                        height="15.3333"
                        rx="1"
                        stroke="#D1E0E5"
                        strokeWidth="0.666667"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_38945_15772">
                        <rect width="24" height="24" rx="1.33333" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_38945_15772">
                        <rect x="-0.00012207" y="4" width="24" height="16" rx="1.33333" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default CreditCardOutlineIcon;
