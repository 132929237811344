import { useTranslation } from 'react-i18next';

import Button from 'ui-kit/button/button';

import IconComposer from 'display-components/icon-composer';

import { formatCurrency } from 'schema/price.schema';

import { noop } from 'util/function';

import './user-balance.styles.scss';

interface UserBalanceProps {
    value: number;
}

const UserBalance = ({ value }: UserBalanceProps) => {
    const { t } = useTranslation();

    const isBalancePositive = value > 0;

    return (
        <div className={`user-balance ${!isBalancePositive && 'negative-balance'}`}>
            <div className="user-balance__icon-container">
                <IconComposer iconType="money" subIconType={isBalancePositive ? 'positive' : 'error'} />
            </div>

            <div className="user-balance__info-container">
                <div>
                    <p className="user-balance__info-container__info">
                        {t(
                            `pages.profile.paymentHistory.userBalance.${
                                isBalancePositive ? 'positiveBalance' : 'negativeBalance'
                            }`
                        )}
                    </p>
                    <p className="user-balance__info-container__value">{formatCurrency(value)}</p>
                </div>
            </div>
            {!isBalancePositive && (
                <div className="user-balance__pay-btn">
                    <Button
                        className="btn-bold btn-outline"
                        label={t('pages.profile.balanceByMember.btnAction')}
                        type="button"
                        variant="primary"
                        onClick={noop}
                    />
                </div>
            )}
        </div>
    );
};

export default UserBalance;
