import classNames from 'classnames';
import React from 'react';

import IconComposer from 'display-components/icon-composer';

import { formatPrice } from 'schema/price.schema';

import { formatDisplayDate, getTransactionStatusDescription } from 'util/payment-history';
import { getCreditCardEnding } from 'util/payments';

import './transaction-card.styles.scss';
import { TransactionCardProps, TransactionTypes } from './transaction-card.types';

const TransactionCardComponent: React.FC<TransactionCardProps> = ({
    transactionInfo,
    iconType,
    subIconType,
    user,
    userType,
    hasDivider
}) => {
    const {
        GLPostStatusNum,
        GLPostAmount,
        GLPostDatetime,
        orderPaymentCardTypeDesc,
        orderPaymentCardNumber,
        transactionType
    } = transactionInfo;

    const isPaymentRefund = transactionType === TransactionTypes.REFUND;
    const hasPaymentErrorStatus = getTransactionStatusDescription(Number(GLPostStatusNum)) === 'Payment Error';
    const statusClass = classNames('transaction-card-content-info-status', {
        isRefund: isPaymentRefund,
        isError: hasPaymentErrorStatus
    });
    const priceClass = classNames({ strikeThruPrice: hasPaymentErrorStatus });
    const containerClass = classNames('transaction-card', { groupedView: hasDivider });

    return (
        <div className={containerClass}>
            <IconComposer
                iconType={iconType}
                subIconType={subIconType}
                orderPaymentCardTypeDesc={orderPaymentCardTypeDesc}
            />
            <div className="transaction-card-content">
                <div className="transaction-card-content-title">
                    <p>{transactionType}</p>
                    <span className={priceClass}>
                        {isPaymentRefund && '+'}
                        {formatPrice(GLPostAmount)}
                    </span>
                </div>
                <div className="transaction-card-content-info">
                    <span className={statusClass}>{getTransactionStatusDescription(Number(GLPostStatusNum))}</span>
                    <span>{formatDisplayDate(GLPostDatetime)}</span>
                    <span>
                        {orderPaymentCardTypeDesc} {getCreditCardEnding(orderPaymentCardNumber, '····')}
                    </span>
                    {/* Provisional solution, as it depends on the API and the new fields to deal with caregiver/dependents */}
                    {user && (
                        <span>
                            {user} {userType === 'caregiver' && '(you)'}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TransactionCardComponent;
