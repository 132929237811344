import classNames from 'classnames';
import React, { CSSProperties } from 'react';

export interface PillOutlineIconProps {
    className?: string;
    style?: CSSProperties;
}
const PillOutlineIcon = ({ className, style }: PillOutlineIconProps) => {
    const classes = classNames('pill-outline-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_38945_16036)">
                    <path
                        d="M7.08928 17.4279L17.4277 7.08952M5.71428 5.71453L9.87923 9.87948M9.87958 5.63649L5.63694 9.87913M2.97979 2.98004C5.8553 0.104535 10.7488 0.410609 13.9159 3.57773L20.4246 10.0864C23.5175 13.1793 23.8901 17.9335 21.1864 20.8523L21.0223 21.0225C18.1468 23.898 13.2532 23.5919 10.0862 20.4248L3.57748 13.9162C0.410365 10.749 0.104291 5.85554 2.97979 2.98004Z"
                        stroke="#93A9B8"
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_38945_16036">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default PillOutlineIcon;
