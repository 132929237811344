import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import BalanceByMemberList from 'display-components/balance-by-member-list';
import MakePayment, { MakePaymentProps } from 'display-components/payment-history/modal/make-payment.component';
import TransactionList from 'display-components/payment-history/transaction-list';
import UserBalance from 'display-components/payment-history/user-balance';

import ProfileLayout from 'components/layouts/profile/profile.layout';

import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';
import { paymentsActions } from 'state/payments/payments.reducers';

import { mockPaymentCards } from 'util/payment-history';

import './payment-history.style.scss';

// DRX-3812: Using mocks to render the component statically, please remove it in API integration ticket
const mockBalanceMembers = [
    { fullName: 'John Doe', totalDue: '26.10', isCaregiver: false },
    { fullName: 'Mary Jane', totalDue: '510.25', isCaregiver: true }
];

// DRX-3798: This is just for displaying purposes, this will be removed after the
// final implementation.
const getRandomUserPaymentInfo = (): MakePaymentProps['paymentInformation'] => {
    const randomIndex = Math.floor(Math.random() * mockBalanceMembers.length);
    const selectedMember = mockBalanceMembers[randomIndex];

    return {
        isCaregiver: selectedMember.isCaregiver,
        amountDue: parseFloat(selectedMember.totalDue)
    };
};

const PaymentHistoryMock = ({ data }: { data: GatsbyTypes.PaymentHistoryMockData }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const fetchPaymentsHistory = useCallback(() => {
        // implement the new endpoint
    }, [dispatch]);

    useEffect(() => {
        fetchPaymentsHistory();
    }, []);

    const handleMakePaymentModal = () => {
        dispatch(
            openModalComponent({
                title: t('pages.profile.payment.modal.makePayment.title'),
                hasDefaultFooter: false,
                hasCustomContent: true,
                content: (
                    <MakePayment
                        paymentInformation={getRandomUserPaymentInfo()}
                        handleCloseModal={() => {
                            dispatch(closeModalComponent());
                            dispatch(paymentsActions.setSelectedPaymentMethod());
                        }}
                    />
                ),
                variation: 'small',
                isCentered: true,
                onClose: () => {
                    dispatch(closeModalComponent());
                    dispatch(paymentsActions.setSelectedPaymentMethod());
                }
            })
        );
    };

    const sections = useMemo(() => {
        const balanceByMember = {
            heading: t('pages.profile.balanceByMember.eyebrowText'),
            children: (
                <div className="balance-container">
                    <BalanceByMemberList onClick={handleMakePaymentModal} members={mockBalanceMembers} />
                </div>
            ),
            suppressChildrenContainer: true
        };

        const paymentHistory = {
            heading: t(`pages.profile.paymentHistory.heading`),
            children: (
                <div className="payment-history-container">
                    <TransactionList transactions={mockPaymentCards} />
                </div>
            ),
            suppressChildrenContainer: true
        };

        const userBalance = {
            children: <UserBalance value={-22} />,
            suppressChildrenContainer: true
        };

        return [userBalance, balanceByMember, paymentHistory];
    }, [t]);

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.paymentHistory.eyebrowText`)}
            title={t(`pages.profile.paymentHistory.title`)}
            suppressChildrenContainer={true}
            sections={sections}
        />
    );
};

export default PaymentHistoryMock;

export const query = graphql`
    query PaymentHistoryMockData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
