import classNames from 'classnames';
import React, { CSSProperties } from 'react';

export interface PlusCircleIconProps {
    className?: string;
    style?: CSSProperties;
}
const PlusCircleIcon = ({ className, style }: PlusCircleIconProps) => {
    const classes = classNames('plus-circle-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="#D6F3E8" />
                <path
                    d="M12 6.4231V16.5769M6.9231 11.5H17.0769"
                    stroke="#00856E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default PlusCircleIcon;
