import classNames from 'classnames';
import React, { CSSProperties } from 'react';

export interface ErrorCircleIconProps {
    className?: string;
    style?: CSSProperties;
}
const ErrorCircleIcon = ({ className, style }: ErrorCircleIconProps) => {
    const classes = classNames('plus-circle-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="#F04C34" />
                <path d="M12 6.5V12.0075M12 16.5L12 15.9495" stroke="white" strokeWidth="2" strokeLinecap="round" />
            </svg>
        </div>
    );
};

export default ErrorCircleIcon;
