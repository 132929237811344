import classNames from 'classnames';
import React, { CSSProperties } from 'react';

export interface MoneyOutlineIconProps {
    className?: string;
    style?: CSSProperties;
}
const MoneyOutlineIcon = ({ className, style }: MoneyOutlineIconProps) => {
    const classes = classNames('money-outline-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 23C18.0752 23 23 18.0752 23 12C23 5.92487 18.0752 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0752 5.92487 23 12 23Z"
                    stroke="#93A9B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.096 8.232C10.912 8.232 10.352 8.648 10.352 9.432C10.352 11.432 15.552 10.792 15.552 14.568C15.552 16.44 14.32 17.64 12.24 17.8L12.224 18.344C12.208 18.984 12.16 19.144 11.52 19.144H11.408C10.768 19.144 10.752 18.984 10.768 18.344L10.784 17.784C9.568 17.656 8.752 17.256 8.288 16.808C8.096 16.632 8 16.44 8 16.264C8 16.072 8.112 15.88 8.304 15.704L8.592 15.448C8.816 15.24 9.008 15.16 9.2 15.16C9.408 15.16 9.6 15.256 9.824 15.416C10.288 15.8 10.88 15.992 11.712 15.992C12.752 15.992 13.472 15.496 13.472 14.584C13.472 12.392 8.256 13.432 8.256 9.448C8.256 7.688 9.36 6.552 11.344 6.36L11.376 5.8C11.424 5.16 11.472 5 12.112 5H12.208C12.848 5 12.88 5.16 12.832 5.8L12.8 6.392C13.616 6.504 14.304 6.792 14.896 7.224C15.024 7.32 15.216 7.48 15.216 7.72C15.216 7.88 15.136 8.056 14.864 8.296L14.56 8.552C14.304 8.776 14.096 8.856 13.936 8.856C13.728 8.856 13.584 8.728 13.472 8.648C13.088 8.408 12.672 8.232 12.096 8.232Z"
                    fill="#93A9B8"
                />
            </svg>
        </div>
    );
};

export default MoneyOutlineIcon;
