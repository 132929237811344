import moment from 'moment';

/* eslint-disable no-unused-vars */
import { TransactionCardProps, TransactionTypes } from 'components/transaction-card/transaction-card.types';

enum StatusTypes {
    PaymentError = 1,
    Posted = 2,
    PaymentCardAuthorized = 4,
    PaymentCardBilled = 5,
    PaymentCardCredited = 6,
    PaymentCardDeclined = 7,
    PaymentVoided = 9
}

const statusDescriptions: { [key in StatusTypes]: string } = {
    [StatusTypes.PaymentError]: 'Payment Error',
    [StatusTypes.Posted]: 'Posted',
    [StatusTypes.PaymentCardAuthorized]: 'Authorized',
    [StatusTypes.PaymentCardBilled]: 'Billed',
    [StatusTypes.PaymentCardCredited]: 'Credited',
    [StatusTypes.PaymentCardDeclined]: 'Declined',
    [StatusTypes.PaymentVoided]: 'Payment Voided'
};

const statusCodes: { [key in StatusTypes]: number[] } = {
    [StatusTypes.PaymentError]: [1, 3, 8, 99],
    [StatusTypes.Posted]: [2],
    [StatusTypes.PaymentCardAuthorized]: [4],
    [StatusTypes.PaymentCardBilled]: [5, 15],
    [StatusTypes.PaymentCardCredited]: [6, 16],
    [StatusTypes.PaymentCardDeclined]: [7],
    [StatusTypes.PaymentVoided]: [9]
};

function getTransactionStatusDescription(code: number): string {
    for (const [key, codes] of Object.entries(statusCodes)) {
        if (codes.includes(code)) {
            return statusDescriptions[key as unknown as StatusTypes];
        }
    }
    return 'Unknown Status';
}

function formatDisplayDate(dateTime: string): string {
    return moment(dateTime, 'MM/DD/YYYY hh:mm:ss A').format('MMM D, YYYY');
}

const groupTransactionsByMonth = (
    transactions: TransactionCardProps[]
): { [month: string]: TransactionCardProps[] } => {
    const groupedTransactions: { [month: string]: TransactionCardProps[] } = {};

    transactions.forEach((transaction) => {
        const date = moment(transaction.transactionInfo.GLPostDatetime, 'MM/DD/YYYY hh:mm:ss A');
        const monthYear = date.format('MMMM, YYYY');

        if (!groupedTransactions[monthYear]) {
            groupedTransactions[monthYear] = [];
        }

        groupedTransactions[monthYear].push(transaction);
    });

    // Order by descending dateTime
    const orderedGroupedTransactions: { [monthYear: string]: TransactionCardProps[] } = {};
    Object.keys(groupedTransactions)
        .sort((a, b) => moment(b, 'MMMM YYYY').diff(moment(a, 'MMMM YYYY')))
        .forEach((key) => {
            orderedGroupedTransactions[key] = groupedTransactions[key];
        });

    return orderedGroupedTransactions;
};

export { getTransactionStatusDescription, formatDisplayDate, groupTransactionsByMonth };

// DRX-3795: using mocks to render the new transactions cards
const mockPayment = [
    {
        DMEItem: false,
        DMEOrder: false,
        EPostScriptId: '',
        GLDebitCredit: 'C',
        GLPaymentNumber: '648',
        GLPostAmount: '$49.00',
        GLPostDatetime: '07/19/2024 12:00:00 AM',
        GLPostNote: 'Payment Extra Visits Telemedecine',
        GLPostStatusDesc: 'PAYMENT_CARD_AUTHORIZED',
        GLPostStatusNum: '4',
        GLPostType: 'Bill Payment Card(Credit)',
        GLPostUser: '',
        epostMemberId: null,
        epostOrderNum: null,
        epostPatientNum: '589306',
        familyId: '1511478',
        orderInvoiceNumber: null,
        orderPaymentCardMonth: 'March',
        orderPaymentCardMonthNum: '3',
        orderPaymentCardNumber: 'T400000402311510002',
        orderPaymentCardSeqNum: '484834',
        orderPaymentCardTypeDesc: 'VISA',
        orderPaymentCardTypeNum: '1',
        orderPaymentCardYear: '2026',
        patientName: 'JOHNNNN,ELTON',
        paymentCardInfo: null,
        runningBalance: null,
        rxNumber: null,
        displayDateTime: '07/19/2024 12:00:00 AM',
        displayDate: '7/19/2024',
        messageErrorText: null,
        messageStatus: null,
        messageText: null
    },
    {
        DMEItem: false,
        DMEOrder: false,
        EPostScriptId: '',
        GLDebitCredit: 'C',
        GLPaymentNumber: '648',
        GLPostAmount: '$49.00',
        GLPostDatetime: '05/19/2024 12:00:00 AM',
        GLPostNote: 'Payment Extra Visits Telemedecine',
        GLPostStatusDesc: 'PAYMENT_CARD_AUTHORIZED',
        GLPostStatusNum: '4',
        GLPostType: 'Bill Payment Card(Credit)',
        GLPostUser: '',
        epostMemberId: null,
        epostOrderNum: null,
        epostPatientNum: '589276',
        familyId: '1511478',
        orderInvoiceNumber: null,
        orderPaymentCardMonth: 'March',
        orderPaymentCardMonthNum: '3',
        orderPaymentCardNumber: 'T400000402311510002',
        orderPaymentCardSeqNum: '484834',
        orderPaymentCardTypeDesc: 'VISA',
        orderPaymentCardTypeNum: '1',
        orderPaymentCardYear: '2026',
        patientName: 'JOHNNNN,ELTON',
        paymentCardInfo: null,
        runningBalance: null,
        rxNumber: null,
        displayDateTime: '07/19/2024 12:00:00 AM',
        displayDate: '7/19/2024',
        messageErrorText: null,
        messageStatus: null,
        messageText: null
    },
    {
        DMEItem: false,
        DMEOrder: false,
        EPostScriptId: '',
        GLDebitCredit: 'C',
        GLPaymentNumber: '648',
        GLPostAmount: '$49.00',
        GLPostDatetime: '12/10/2023 12:00:00 AM',
        GLPostNote: 'Payment Extra Visits Telemedecine',
        GLPostStatusDesc: 'PAYMENT_CARD_AUTHORIZED',
        GLPostStatusNum: '4',
        GLPostType: 'Bill Payment Card(Credit)',
        GLPostUser: '',
        epostMemberId: null,
        epostOrderNum: null,
        epostPatientNum: '589277',
        familyId: '1511478',
        orderInvoiceNumber: null,
        orderPaymentCardMonth: 'May',
        orderPaymentCardMonthNum: '5',
        orderPaymentCardNumber: 'T512121163333252124',
        orderPaymentCardSeqNum: '484648',
        orderPaymentCardTypeDesc: 'MASTER CARD',
        orderPaymentCardTypeNum: '2',
        orderPaymentCardYear: '2027',
        patientName: 'WORKS,MASTER',
        paymentCardInfo: null,
        runningBalance: null,
        rxNumber: null,
        displayDateTime: '07/12/2024 12:00:00 AM',
        displayDate: '7/12/2024',
        messageErrorText: null,
        messageStatus: null,
        messageText: null
    }
];

export const mockPaymentCards: TransactionCardProps[] = [
    {
        transactionInfo: { ...mockPayment[0], GLPostStatusNum: '6', transactionType: TransactionTypes.REFUND },
        iconType: 'money',
        subIconType: 'refund',
        user: 'Leticia',
        userType: 'caregiver'
    },
    {
        transactionInfo: { ...mockPayment[0], GLPostStatusNum: '1', transactionType: TransactionTypes.ONLINE_VISIT },
        iconType: 'visits',
        subIconType: 'error',
        user: 'Leticia',
        userType: 'caregiver'
    },
    {
        transactionInfo: { ...mockPayment[0], GLPostStatusNum: '2', transactionType: TransactionTypes.MEMBERSHIP_FEE },
        iconType: 'membership',
        subIconType: 'default',
        user: 'Mamey',
        userType: 'dependent'
    },
    {
        transactionInfo: { ...mockPayment[0], GLPostStatusNum: '6', transactionType: TransactionTypes.REFUND },
        iconType: 'money',
        subIconType: 'refund',
        user: 'Qweqw',
        userType: 'dependent'
    },
    {
        transactionInfo: { ...mockPayment[0], GLPostStatusNum: '1', transactionType: TransactionTypes.ONLINE_VISIT },
        iconType: 'visits',
        subIconType: 'error',
        user: 'Jafijo',
        userType: 'dependent'
    },
    {
        transactionInfo: { ...mockPayment[0], GLPostStatusNum: '2', transactionType: TransactionTypes.MEMBERSHIP_FEE },
        iconType: 'membership',
        subIconType: 'default',
        user: 'Jafijo',
        userType: 'dependent'
    },
    {
        transactionInfo: { ...mockPayment[1], transactionType: TransactionTypes.PRESCRIPTION },
        iconType: 'pill',
        subIconType: 'default',
        user: 'Mamey',
        userType: 'dependent'
    },
    {
        transactionInfo: { ...mockPayment[1], transactionType: TransactionTypes.PRESCRIPTION },
        iconType: 'pill',
        subIconType: 'default',
        user: 'Leticia',
        userType: 'caregiver'
    },
    {
        transactionInfo: { ...mockPayment[2], transactionType: TransactionTypes.ONLINE_VISIT },
        iconType: 'visits',
        subIconType: 'default',
        user: 'Qweqw',
        userType: 'dependent'
    },
    {
        transactionInfo: {
            ...mockPayment[2],
            GLPostStatusNum: '2',
            GLPostAmount: '15',
            transactionType: TransactionTypes.BALANCE_SETTLEMENT
        },
        iconType: 'money',
        subIconType: 'default',
        user: 'Leticia',
        userType: 'caregiver'
    }
];
